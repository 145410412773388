
<template>
    <div> callback </div>
</template>

<script>
import http from '../mixins/api'
import store from '@/store'
// const { Issuer } = require('openid-client');

export default {
    name: 'Callback',
    components: {
    },
    data: function() {
        return {
        }
    },
    async beforeRouteEnter(route, redirect, next) {
        console.log(route)
        // Issuer.discover('https://accounts.google.com') // => Promise
        //   .then(function (googleIssuer) {
        //     console.log('Discovered issuer %s %O', googleIssuer.issuer, googleIssuer.metadata);
        //   });

        let query = route.query

        // const obj = {
        //     grant_type: "client_credentials",
        //     code: "query.code",
        //     scope: "openid offline photos.read",
        // };
        // const method = "POST";
        // const body = Object.keys(obj).map((key)=>key+"="+encodeURIComponent(obj[key])).join("&");
        // console.log(query)
        // console.log(body)
        // const headers = {
        //     // "Authorization": "Basic ZmFjZWJvb2stcGhvdG8tYmFja3VwOnNvbWUtc2VjcmV0",
        //     "Authorization": "Basic ZmFjZWJvb2stcGhvdG8tYmFja3Vwc29tZS1zZWNyZXQ=",
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        // };
        // fetch("https://localhost:9000/oauth2/token", {method, headers, body}).then((res)=> res.json()).then(console.log).catch(console.error);

        // store.commit("auth/logout")
        await store.dispatch("auth/logout")

        const res = await http.post("/auth/Login", {
            grant_type: "client_credentials", 
            params: query, 
            code_verifier: localStorage.code_verifier, 
            state: localStorage.state, 
            nonce: localStorage.nonce
        }, null, false, false)

        console.log(res)
        if(res.Success) {
            delete localStorage.code_verifier;
            delete localStorage.state;
            delete localStorage.nonce;
            store.commit("auth/login", {
                token: res.token,
            })
            store.commit("auth/setUserInfo", res.userInfo)
            // await store.dispatch('routeStore/getRouteList')
            // await store.dispatch('userBranchInfo/setMemberList')
            
            if(res.redirect_url) {
                localStorage.redirect_url = res.redirect_url;
            }
            if(localStorage.redirect_url) {
                const redirect_url = localStorage.redirect_url
                location.href = redirect_url
                delete localStorage.redirect_url
                next()
            } else {
                next("/admin_login")
            }
            return
        }
        next("/admin_login")
    },
    created() {
    },
    computed: {
    },
    methods: {
    },
}
</script>


